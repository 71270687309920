@import 'components/mixins.scss';

.sidebar {
  position: fixed;
  top: 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.collapsedSidebar {
  position: fixed;
  top: 0;
  width: 80px;
  overflow: hidden;
}

.menu {
  flex: 1;
  border: 0;
}


.loadingBoxContainer {
  --size: 40px;
  --radius: 3px;
  position: relative;
  width: 100%;
  height: var(--size);
  padding: var(--radius);
  border-radius: var(--radius);
  overflow: hidden;
}

.loadingBox {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: var(--radius);
}

.loadingBoxContainer::before {
  content: '';
  width: 150%;
  height: 150%;
  position: absolute;
  top: -25%; left: -25%;
  background: conic-gradient(#5adbee, #5adbee85);
  animation: rotate-border 5s linear infinite;
}

@keyframes rotate-border {
  to {
    transform: rotate(360deg);
  }
}