.ant-menu-item {
  &.habit-menu-item {
    display: flex;
    flex-direction: column;
    height: auto !important;
    line-height: 1.4 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    /* margin: 1rem 1rem 1rem 0 !important; */
    .card {
      margin: 0;
      /*  border-left: 2px solid gray;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; */
    }
  }
}
/* 
.progress-info-month .ant-badge-count {
  top: 50% !important;
  transform: translate(-20%, -50%) !important;
} */

.goalCalendar {
  .ant-picker-calendar-date-content {
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    overflow: visible !important;
    > span {
      width: 100%;
      text-align: center;
    }
    /*     .ant-badge-count {
      top: auto;
      transform: none;
    } */
  }
}
