@import 'components/mixins.scss';

.ant-badge:not(.ant-badge-not-a-wrapper) {
  /* margin-right: 20px; */
}
.ant-badge.ant-badge-rtl:not(.ant-badge-not-a-wrapper) {
  margin-right: 0;
  /* margin-left: 20px; */
}
.progress-info {
  padding: 0.5em !important;
  border-radius: 2px;
  background: #eee;
  display: flex !important;
  align-self: center;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  flex-direction: column;
  gap: 0.25em;
  &.success {
    background-color: $success;
    color: white;
  }
  &.danger {
    background-color: $danger;
    color: white;
  }
  &.warning {
    background-color: $warning;
  }
}
.progress-info svg {
  z-index: 10;
}
[data-theme='dark'] .progress-info {
  background: rgba(255, 255, 255, 0.12);
}

.progress-info .ant-badge-count {
  z-index: 10;
  margin-left: 0.25em;
}
