$primary: #02d4c5;
$white: #ffffff;
$black: #000000;
$lightRed: rgba(255, 0, 0, 0.65);
$green: #35a497;
$malachite: #00d85e;
$paleGrey: #f2f2f2;
$lightGrey: #dadbdd;
$red: #ab1316;
$brightRed: #ff0000;
$coral: #ff8343;
$candyCorn: #fbf45d;
$cottonCandy: #ffaee4;
$purple: #8508aa;
$gulfStream: #81aeac;
$facebook: #4267b2;
$twitter: #38a1f3;
$google: #dd4b39;
$robinEggBlue: #02d4c5;
$linkWater: #e4eaf6;
$blueBayoux: #51617b;
$chetwodeBlue: #82a4dc;
$alabaster: #fafafa;
$cornflowerBlue: #686dff;
$whiteIce: #e5fbf9;
$lightslateGrey: #798b9b;
$reef: #d3ffa7;
$mintTulip: #ccf6f3;
$kournikova: #ffde74;
$gullGray: #9daebe;
$whiteLilac: #f9fafd;
$karry: #ffe8d9;
$corn: #e9bc07;
$blueRomance: #ccf7de;
$tableRow: rgba(157, 174, 190, 0.1);
