@import 'components/mixins.scss';

//////////////////////////////////////////////////////////////////////////////////////////
/* AUTH */

.container {
  padding: rem(50) rem(30);
}

.forgotPasswordWrapper {
  max-width: 400px;
  margin: 50px auto;
}
