@import 'components/mixins.scss';
@import 'colors.scss';

.dialogs {
  /* height: 100%; */
  background-color: white;

  @media (max-width: $sm-max-width) {
    max-height: rem(240);
    margin-bottom: rem(15);
    height: rem(300);
  }
}

.listItem {
  padding: 0.6rem 0.66rem;
  background-color: white;
  border: 0;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 8px;

  &:hover {
    background-color: #0aadf0;
    color: white;
  }
  :global {
    .more {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
}

.current {
  background-color: #0aadf0;
  color: white;
}

.info {
  min-width: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-right: 20px;
}

.unread {
  min-width: 15px;
}

.avatar {
  width: 18px !important;
  height: 18px !important;
}

.message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  /* overflow: hidden; */
  overflow: visible;
  flex-shrink: 0;
  margin-right: 5px;

  margin-left: 1em;

  .downloadDocWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    .downloadIcon {
      margin-right: 5px;
      path {
        fill: #fff;
      }
    }
  }

  .deletedMessage {
    overflow: hidden;
    word-break: break-all;
  }

  .messageWrapper {
    width: 60%;
  }
  &.answer {
    flex-direction: row-reverse;
    margin-left: 5px;
    margin-right: 1em;
    .downloadIcon {
      path {
        fill: #000000d9;
      }
    }
    .messageAvatar {
      margin-left: 0;
      margin-right: rem(15);
    }
    .messageContent {
      &::before {
        left: auto;
        right: 100%;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 4px solid white;
        border-left: none;
      }
    }
  }
  &:not(.answer) {
    .downloadIcon {
      path {
        fill: #fff;
      }
    }
    .messageContent {
      background-color: #0aadf0;
      color: $whiteLilac;
      &::before {
        border-left-color: #0aadf0;
      }
    }
  }
  &.deleted {
    .messageContent {
      margin-top: 12px;
      background-color: transparent;
      padding: 0;
      &::before {
        border-color: transparent;
      }
    }

    .deletedMessage {
      padding: 2px 12px;
      margin-top: 4px;
      border-radius: 25px;
      background-color: #eee;
      border: 1px solid #aaa;
      color: #555;
    }
  }
}
.downloadChat {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}
.messageAvatar {
  flex-shrink: 0;
  margin-left: rem(15);
}

.listAvatar {
  border-radius: 8px;
  img {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
  }
}

.messageContent {
  background-color: white;
  position: relative;
  border-radius: 12px;
  padding-left: rem(15);
  padding-right: rem(15);
  padding-top: rem(6);
  padding-bottom: rem(6);
  overflow: hidden;
  word-break: break-all;
  &::before {
    content: '';
    position: absolute;
    left: 100%;
    bottom: 16px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 4px solid white;
    border-right: none;
  }

  img {
    border-radius: 12px;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .messageContent {
    background-color: $dark-gray-5;
    &:before {
      border-left-color: $dark-gray-5;
    }
  }
  .message {
    &.answer {
      .messageContent {
        &:before {
          border-right-color: $dark-gray-5;
        }
      }
    }
  }
  .item {
    &:hover,
    &.current {
      background-color: $dark-gray-4;
    }
  }
}

.Conversations {
  padding: 6px;
  height: 100%;
}

.notActivatedList {
  li {
    opacity: 0.5;
    &:hover,
    &:focus-within {
      opacity: 1;
    }
  }
}

.attachment {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.fileBox {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e4e9f0;

  img {
    height: 100%;
    width: auto;
  }
}

.chatSide {
  width: 320px;
}

.chatInfo {
  width: 240px;
}

.chatView {
  background-color: #f3f4ff;
  flex: 1;
}

.cardHeader {
  height: 62px;
  background-color: white;
  border-left: 2px solid #f3f4ff;
  border-right: 2px solid #f3f4ff;
  border-bottom: 2px solid #f3f4ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listHeader {
  height: 62px;
  border-bottom: 3px solid #eceffd;
  background-color: white;
}

.conversationList {
  background-color: #eceffd;

  .searchInput {
    border: none;
    border-radius: 24px;

    svg {
      color: #0aadf0;
    }
  }

  .tabContent {
    background-color: #eceffd;
  }
}

.chatViewWrapper {
  height: calc(100vh - 145px);
  position: relative;

  .chatViewContent {
    height: calc(100% - 70px);
  }

  .typingGroupWrapper {
    position: absolute;
    width: 100%;
    padding: 0 16px 8px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f3f4ff;
  }

  .typingGroup {
    border-radius: 8px;
    background-color: white;
    padding: 8px;
    bottom: 12px;
    box-shadow: 0px 0px 14px 0px #0001;

    .appendButton {
      position: relative;
      padding: 2px;
      cursor: pointer !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .textInput {
      border: 0;
      flex: 1;
    }

    .sendButton {
      background-color: #0aadf0;
      border: none;
      padding: 0;
      width: 38px;
      height: 38px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: white;
      }
    }

    .iconButton {
      font-size: 24px;
      color: #8d8d8d;
      margin: 0 4px;
      cursor: pointer;
    }
  }
}

.channels {
  height: calc(100vh - 268px);
}

.members {
  height: calc(100vh - 178px);
}

.chatMessage {
  a {
    color: royalblue !important;
  }
}

.group-avatar {
  position: relative;
  width: fit-content;
  cursor: pointer;
  &-edit-icon {
    position: absolute;
    right: 0;
    top: 0px;
  }

  &-avatar {
    border-radius: 5px;
    width: 100px;
    object-fit: contain;
    max-height: 100px;
  }
}
