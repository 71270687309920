.usercard {
  width: 100%;
  border-radius: 0 !important;
  @media only screen and (min-width: 768px) {
    width: calc(50% - 1em);
  }
  @media only screen and (min-width: 1200px) {
    width: calc(33% - 1em);
  }
  @media only screen and (min-width: 1600px) {
    width: calc(25% - 1em);
  }
  @media only screen and (min-width: 2000px) {
    width: calc(20% - 1em);
  }
}
