@import 'colors.scss';
@import 'components/mixins.scss';

.goalItemHeader {
  color: $black;
}

.goalItemDetailHeader {
  color: $gulfStream;
  font-weight: 300;
  text-transform: uppercase;
}

.goalDetailItem {
  > div {
    &:last-of-type {
      border-right: none !important;
    }
  }
}

.goalItem {
  border-bottom-color: #aaa !important;
  &:last-of-type {
    border: 0;
  }
}
