$habLight: #02ad9f;
$habOrange: #ffa031;
$habMain: #986fad;

// Colors
$white: #fff;
$black: #141322;
$blue: var(--kit-color-primary);
$blue-light: #42baf9;
$blue-dark: #2c60e4;
$gray-1: #f2f4f8;
$gray-2: #e4e9f0;
$gray-3: #d9dee9;
$gray-4: #c8c4db;
$gray-5: #a1a1c2;
$gray-6: #595c97;
$yellow: #ff0;
$orange: #faad15;
$red: #f5222e;
$pink: #fd3995;
$purple: #652eff;
$green: #41b883;

$lightRed: rgba(255, 0, 0, 0.65);
$green: #35a497;
$malachite: #00d85e;
$paleGrey: #f2f2f2;
$lightGrey: #dadbdd;
$red: #ab1316;
$brightRed: #f00;
$coral: #ff8343;
$candyCorn: #fbf45d;
$cottonCandy: #ffaee4;
$purple: #8508aa;
$gulfStream: #81aeac;
$facebook: #4267b2;
$twitter: #38a1f3;
$google: #dd4b39;
$robinEggBlue: #02d4c5;
$linkWater: #e4eaf6;
$blueBayoux: #51617b;
$chetwodeBlue: #82a4dc;
$alabaster: #fafafa;
$cornflowerBlue: #686dff;
$whiteIce: #e5fbf9;
$lightslateGrey: #798b9b;
$reef: #d3ffa7;
$mintTulip: #ccf6f3;
$kournikova: #ffde74;
$gullGray: #9daebe;
$whiteLilac: #f9fafd;
$karry: #ffe8d9;
$corn: #e9bc07;
$blueRomance: #ccf7de;

$primary: $coral;

$text: $gray-6;
$border: $gray-2;

// Accent colors
$default: $gray-4;
$primary: $primary;
$secondary: $habLight;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;

// Font Family
$base-font-family: var(--kit-font-family);

// Font Size
$base-font-size: 15 !default;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
