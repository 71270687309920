/* 
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; */

@import './colors.scss';

@import '~bootstrap/scss/bootstrap.scss';
@import '~react-perfect-scrollbar/dist/css/styles.css';
//@import "~antd/dist/antd.css"; // or 'antd/dist/antd.less'

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style'; // Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles

body {
  margin: 0;
}

.ant-btn {
  &.btn-secondary {
    border-color: #02ad9f;
    color: #02ad9f;
    &:hover,
    &:focus {
      background-color: #02ad9f;
      color: white;
    }
  }
  &.btn-sm {
    line-height: 1;
    padding: 0.5em !important;
    height: auto;
  }
}

.habit-tabs {
  .ant-btn {
    color: $gulfStream;
    border-radius: 0.25em;
    &.active {
      background-color: #02ad9f !important;
    }
    &:focus {
      box-shadow: 0 0 0 1px white, 0 0 0 3px #02ad9f !important;
    }
  }
}

.h6 {
  text-transform: uppercase;
}
.ant-picker-calendar-mode-switch {
  display: none !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border: 2px solid #1890ff !important;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  overflow-y: unset !important;
}

.ant-picker-calendar-header {
  justify-content: left !important;
  padding: 12px 12px !important;
}

.ct-chart-donut .ct-label {
  font-size: 2rem;
}
/* 
.ant-menu-inline-collapsed-tooltip {
  display: none;
} */

.card {
  &.card-shadow {
    border-radius: calc(0.25rem - 1px);
    border-color: #ddd;
    box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.03), 0 0 10px 0 rgba(20, 19, 34, 0.02);
  }
}

.dashboard-list {
  &.list-group.list-group-horizontal {
    .list-group-item {
      border-top: 0;
      border-bottom: 0;
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.ps__rail-y {
  z-index: 1000;
}

.main-container {
  position: relative;
  padding: 1rem;
}

.upMore {
  border-top: 1px solid #ddd;
  box-shadow: 0 -0.25rem 0.25rem -0.33rem rgba(50, 50, 50, 0.75);
  &.downMore {
    box-shadow: 0 0.25rem 0.25rem -0.33rem rgba(50, 50, 50, 0.75),
      0 -0.25rem 0.25rem -0.33rem rgba(50, 50, 50, 0.75);
  }
}
.downMore {
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0.25rem 0.25rem -0.33rem rgba(50, 50, 50, 0.75);
}

@include media-breakpoint-up(lg) {
  .list-group-flush.list-group-horizontal-lg {
    .list-group-item {
      border: 0;
      border-right: thin solid #e4e9f0;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.stacked-bar {
  ul {
    margin-bottom: 0;
  }
  margin-bottom: 1rem;
}

.stacked-bar {
  padding-bottom: 3rem;
  svg {
    overflow: visible !important;
  }
  foreignObject {
    position: relative;
    .ct-label.ct-horizontal.ct-end {
      position: absolute;
      transform: rotate(-45deg);
      right: 1rem;
      top: 1.5rem;
    }
  }
}
.habits-line-chart {
  svg {
    overflow: visible !important;
  }
  foreignObject {
    position: relative;
    .ct-label.ct-horizontal.ct-end {
      position: absolute;
      transform: rotate(-45deg);
      left: -3rem;
      top: 1.5rem;
      width: auto !important;
    }
  }
}

.ct-legend {
  position: relative;
  z-index: 10;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.5rem;
  li,
  a {
    position: relative;
    padding-left: 2.5rem;
    list-style: none;
  }

  li:before,
  a:before {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0.5rem;
    content: '';
    /* border: 3px solid transparent; */
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
  }

  li.inactive:before {
    background: transparent;
  }

  &.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
  }
}

$ct-series-colors: (
  'a': #f4c63d,
  'b': #0544d3,
  'c': #d70206,
  'd': #59922b,
  'e': #f05b4f,
  'f': #453d3f,
  'g': #6188e2,
  'h': #d17905,
  'i': #a748ca,
  'j': #dda458,
  'k': #6b0392,
  'l': #f05b4f,
  'm': #eacf7d,
  'n': #86797d,
  'o': #b2c326,
  'p': #b284be,
  'q': #f19cbb,
  'r': #ff7e00,
  's': #665d1e,
  't': #841b2d,
  'u': #faebd7,
  'v': #00ffff,
  'w': #8f9779,
  'x': #e9d66b,
  'y': #87a96b,
  'z': #ff91af
) !default;
$i: 0;
@each $value, $key in $ct-series-colors {
  /* $key: nth($color, 1);
  $value: nth($color, 2); */

  .ct-series-#{$i}:before {
    background-color: $value;
    border-color: $value;
  }
  .badge.ct-series-#{$i} {
    background-color: $value;
    border-color: $value;
    color: black !important;
    font-weight: bolder !important;
  }
  .ct-series-#{$key} {
    .ct-bar {
      stroke: $value !important;
    }
  }
  .ct-series-#{$key} .ct-bar,
  .ct-series-#{$key} .ct-line {
    border-color: $value;
  }
  .habit-week-list .card:nth-child(#{$i + 1}) {
    border-left: 4px solid $value;
  }
  .bg-ct-series-#{$i} {
    background-color: $value !important;
  }
  $i: $i + 1;
}

.habits-line-chart {
  border-bottom: thin solid #eee;
  margin-bottom: 2em;
  padding-bottom: 7.5rem !important;
  .ct-chart-line {
    height: 250px !important;
  }
  .ct-legend {
    display: flex;
    gap: 1em;
    li,
    a {
      align-items: center;
      display: flex;
      /* cursor: pointer; */
    }
  }
}

.list-group-item {
  &.active {
    background-color: lighten(#02ad9f, 5);
    border-color: $gullGray;
    border-left: 0;
    border-right: 0;
    &:before {
      border: thin solid white;
    }
  }
}

.rounded {
  border-radius: 50% !important;
  overflow: hidden;
}

.chat-card-header {
  height: 62px;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.ant-tabs-nav-list {
  width: 100% !important;

  .ant-tabs-tab {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
