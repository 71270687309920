/* .latest-container {
  margin: 0 -2em 0.5em -2em;
} */

.latest-container {
  li {
    padding-left: 0;
    padding-right: 0;
  }
}

.latest-list {
  padding: 0;
  display: flex;
  gap: 1em;
  margin-bottom: 0.5em;
  li {
    border-bottom: thin solid #f1f1f1;
    padding: 1em;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    border-radius: 0;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 275px;
    box-shadow: 0 0.5em 1em 0 rgba(20, 19, 34, 0.03), 0 0 1em 0 rgba(20, 19, 34, 0.02);
    &:first-child {
      margin-left: 2em;
    }
    &:last-child {
      margin-right: 2em;
    }
    dl {
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      margin: 0;
      dt {
        font-size: 0.8em;
      }
      dd {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
