@import 'colors.scss';

h3.dayHeader {
  white-space: nowrap;
  font-size: 0.75rem;
  text-transform: none;
  width: 100%;
  text-align: center;
  padding: 0.5em 0;
  margin: 0;
  background-color: $mintTulip;
}
