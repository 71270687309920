@import 'colors.scss';

.messageListing {
  align-items: flex-start;
  border-top: thin solid #ddd !important;
  border-bottom: 0 !important;
  &:first-child {
    border-top: 0 !important;
  }
  .itemHeader {
    background-color: $mintTulip;
  }
}
