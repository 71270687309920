.logo {
  margin-left: 1rem;
  max-height: 6rem;
}

.login-container {
  max-width: 33rem !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0;
  }

  .ant-btn > .ant-btn-loading-icon {
    margin-right: 0.5em;
  }
}
.forgot-link {
  cursor: pointer;
  color: #1a0dab;
}
.login-checkbox {
 span {
   padding-right: 0 !important;
 }
}
.link-terms {
  cursor: pointer;
  color: #1a0dab;
}