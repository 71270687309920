.videoCallWrapper {
  width: 100%;
  display: flex;

  .videoCallScreen {
    width: calc(100% - 350px);
    position: relative;
    .vid {
      width: 100%;
      height: calc(100vh - 64px);

      &.my-video {
        position: absolute;
        width: 140px;
        height: 140px;
        z-index: 99;
        right: 30px;
        top: 30px;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
  .chatViewWrapper {
    width: 350px;
  }
}

.controls {
  position: absolute;
  bottom: 30px;
  z-index: 99;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
.controls-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  backdrop-filter: blur(10px);
  background: rgb(0 0 0 / 28%);
  padding: 10px 20px;
  border-radius: 8px;

  p {
    margin: 0;
  }
}
.video-action-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.invert-image {
  filter: invert(1);
}
.nodata-bg {
  background: #0c0c1b38;
  padding: 24px;
}
.allVoideoScreen {
  position: relative;
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.off-video-icon {
  max-width: 300px;
  width: 80%;
  margin: auto;
}
